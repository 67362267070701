import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { injectIntl } from 'helpers'
import { FormattedMessage, Layout, Section, Container, Link } from 'components'

const BlogPage = ({
  data: { allContentfulBlog },
  intl: { locale },
  ...props
}) => {
  const posts = allContentfulBlog.edges
    .filter(({ node }) => node.node_locale === locale)
    .map(({ node }) => node)
  return (
    <Layout>
      <Section>
        <Container>
          <h1>
            <FormattedMessage id="blog" />
          </h1>
          <p>
            <FormattedMessage id="blog-page-description" />
          </p>
          <ul>
            {posts.map(({ id, slug, title, node_locale }) => (
              <li key={id}>
                <Link to={`/blog/${slug}`}>
                  {title} - {node_locale}
                </Link>
              </li>
            ))}
          </ul>
        </Container>
      </Section>
    </Layout>
  )
}

export default injectIntl(BlogPage)

export const query = graphql`
  {
    allContentfulBlog {
      edges {
        node {
          id
          slug
          title
          content {
            content
          }
          node_locale
        }
      }
    }
  }
`
